import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/layout.tsx";
import Intro from "../components/v8-ignition/intro";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Intro mdxType="Intro" />
    <hr></hr>
    <h3>{`منابع`}</h3>
    <div dir="rtl" className="resources">
      <p>{`لیست بایت‌کد ها اینجا می‌تونید ببینید:`}</p>
      <p>{`[1]`}{` `}<a parentName="p" {...{
          "href": "https://github.com/v8/v8/blob/main/src/interpreter/bytecodes.h"
        }}>{`https://github.com/v8/v8/blob/main/src/interpreter/bytecodes.h`}</a></p>
      <p>{`اینکه هر دستور چه چیزی رو اجرا می‌کنه هم می‌تونید کلیتش رو از اینجا متوجه بشید:`}</p>
      <p>{`[2]`}{` `}<a parentName="p" {...{
          "href": "https://github.com/v8/v8/blob/main/src/maglev/maglev-graph-builder.cc"
        }}>{`https://github.com/v8/v8/blob/main/src/maglev/maglev-graph-builder.cc`}</a></p>
      <p>{`اگر براتون این بایت‌کد ها جالب بود حتما پیشنهاد میکنم این مقاله رو بخونید:`}</p>
      <p>{`[3]`}{` `}<a parentName="p" {...{
          "href": "https://medium.com/dailyjs/understanding-v8s-bytecode-317d46c94775"
        }}>{`https://medium.com/dailyjs/understanding-v8s-bytecode-317d46c94775`}</a></p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      